import React from 'react'

export default () => (
  <div>
    <div>
      <br/>
      <a href="/" onClick="op('WEBzona'); return false;"/><p/>
      <div id="WEBzona" style={{display: 'none'}}>
        <h2><strong>Gabinet ginekologiczny w Wołominie</strong></h2>
        <p>Lek. Andrzej Lipa oraz dr n. med. Michał Lipa wspólnie prowadzą przychodnię ginekologiczną w Wołominie.<br/>
          Usługi medyczne prywatnie.</p>
        <table>
          <tbody>
          <tr>
            <td>
              <h3><strong>lekarz ginekolog prywatnie w Wołominie</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>prowadzenie ciąży w Wołominie</strong></td>
          </tr>
          <tr>
            <td>
              <h3><strong>gabinet ginekologiczny w Wołominie</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>ultrasonografi &amp; usg ginekologiczne Wołomin</strong></td>
          </tr>
          <tr>
            <td>
              <h3><strong>Badania: ciąża bliźniacza, patologia ciąży i terapia płodu.</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>Położnictwo / konsultacja położnicza w Wołominie.</strong></td>
          </tr>
          <tr>
            <td>
              <h3><strong>Dobry ginekolog w Wołominie.</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>Tiliamed jest jedną z najlepszych poradni ginekologicznych pod Warszawą - w Wołominie.</strong>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
)
